import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/pages/Home"),
  },
  {
    path: "/set",
    name: "Set",
    component: () => import("@/pages/AnswerSet"),
  },
  {
    path: "/answers",
    name: "Answerset",
    component: () => import("@/pages/Answers"),
  },
  {
    path: "/result",
    name: "Result",
    component: () => import("@/pages/Result"),
  },
  {
    path: "/revision",
    name: "Revision",
    component: () => import("@/pages/Revision"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
